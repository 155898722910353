interface IProps {
    className?: string;
    onclick?: () => void;
}

export const ChatIcon: React.FC<IProps> = ({ className }) => (
    <svg viewBox='0 0 40 41' className={className}>
        <path
            d='M33.3333 10.5H31.6666V23.8333C31.6666 24.75 30.9166 25.5 29.9999 25.5H9.99992V27.1666C9.99992 29 11.4999 30.5 13.3333 30.5H29.9999L36.6666 37.1666V13.8333C36.6666 12 35.1666 10.5 33.3333 10.5ZM28.3333 18.8333V7.16665C28.3333 5.33331 26.8333 3.83331 24.9999 3.83331H6.66659C4.83325 3.83331 3.33325 5.33331 3.33325 7.16665V28.8333L9.99992 22.1666H24.9999C26.8333 22.1666 28.3333 20.6666 28.3333 18.8333Z'
            fill='#6BF389'
        />
    </svg>
);

export const CloseIcon: React.FC<IProps> = ({ className }) => (
    <svg viewBox='0 0 20 20' className={className}>
        <path
            d='M15.2501 4.75843C14.9251 4.43343 14.4001 4.43343 14.0751 4.75843L10.0001 8.8251L5.9251 4.7501C5.6001 4.4251 5.0751 4.4251 4.7501 4.7501C4.4251 5.0751 4.4251 5.6001 4.7501 5.9251L8.8251 10.0001L4.7501 14.0751C4.4251 14.4001 4.4251 14.9251 4.7501 15.2501C5.0751 15.5751 5.6001 15.5751 5.9251 15.2501L10.0001 11.1751L14.0751 15.2501C14.4001 15.5751 14.9251 15.5751 15.2501 15.2501C15.5751 14.9251 15.5751 14.4001 15.2501 14.0751L11.1751 10.0001L15.2501 5.9251C15.5668 5.60843 15.5668 5.0751 15.2501 4.75843Z'
            fill='white'
        />
    </svg>
);

export const SendIcon: React.FC<IProps> = ({ className }) => (
    <svg width='20' height='20' viewBox='0 0 20 20' className={className}>
        <path
            d='M3.05924 17L17.6009 10.7667C18.2759 10.475 18.2759 9.52499 17.6009 9.23333L3.05924 2.99999C2.50924 2.75833 1.90091 3.16666 1.90091 3.75833L1.89258 7.59999C1.89258 8.01666 2.20091 8.37499 2.61758 8.42499L14.3926 9.99999L2.61758 11.5667C2.20091 11.625 1.89258 11.9833 1.89258 12.4L1.90091 16.2417C1.90091 16.8333 2.50924 17.2417 3.05924 17Z'
            fill='#01300B'
        />
    </svg>
);

export const ThumpsUpICon: React.FC<IProps> = ({ className, onclick }) => (
    <svg
        width='16'
        height='16'
        viewBox='0 0 43 45'
        fill='none'
        className={className}
        onClick={onclick}
    >
        <path
            d='M35.3908 43.2908H38.6424C40.4396 43.2908 41.8965 41.8339 41.8965 40.0367L41.8965 17.2654C41.8965 15.4682 40.4396 14.0113 38.6424 14.0113H35.3908C33.5936 14.0113 32.1367 15.4682 32.1367 17.2654V40.0367C32.1367 41.8339 33.5936 43.2908 35.3908 43.2908Z'
            stroke='#2FAF4B'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M32.1367 15.6398L30.4313 15.6167C29.4425 15.6038 28.5153 15.1415 27.9066 14.3607L17.5149 1.00006H17.4867C13.4903 1.00006 10.6677 4.91684 11.9313 8.70776L14.2428 15.6398H5.15078C3.11405 15.6398 1.57817 17.4916 1.95315 19.4924L5.61309 39.012C5.90075 40.5505 7.244 41.6652 8.81071 41.6652H32.1367'
            stroke='#2FAF4B'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M37.0156 18.8911V22.1453'
            stroke='#2FAF4B'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M12.6172 22.1453H2.85735'
            stroke='#2FAF4B'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M14.2422 28.651H4.48235'
            stroke='#2FAF4B'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M15.8711 35.1594H6.11126'
            stroke='#2FAF4B'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
    </svg>
);

export const ThumpsDownICon: React.FC<IProps> = ({ className, onclick }) => (
    <svg
        width='16'
        height='16'
        viewBox='0 0 43 45'
        fill='none'
        className={className}
        onClick={onclick}
    >
        <path
            d='M8.40218 1H5.15062C3.35341 1 1.89648 2.45691 1.89648 4.25411V27.0254C1.89648 28.8226 3.35341 30.2795 5.15062 30.2795H8.40218C10.1994 30.2795 11.6563 28.8226 11.6563 27.0254V4.25411C11.6563 2.45691 10.1994 1 8.40218 1Z'
            stroke='#FF8B52'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M11.6562 28.651L13.3617 28.6741C14.3505 28.6869 15.2777 29.1493 15.8864 29.93L26.278 43.2907H26.3063C30.3027 43.2907 33.1253 39.3739 31.8617 35.583L29.5501 28.651H38.6422C40.6789 28.651 42.2148 26.7992 41.8398 24.7984L38.1799 5.27873C37.8922 3.74027 36.549 2.62561 34.9823 2.62561H11.6562'
            stroke='#FF8B52'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M6.77734 25.3997V22.1455'
            stroke='#FF8B52'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M31.1758 22.1455H40.9356'
            stroke='#FF8B52'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M29.5508 15.6398H39.3106'
            stroke='#FF8B52'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M27.9219 9.13135H37.6817'
            stroke='#FF8B52'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
    </svg>
);
