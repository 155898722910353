import React, { useEffect, useState } from "react";
import { ChatIcon } from "shared/icon/icon";

const UmWeltDemo: React.FC = () => {
    const [isOpenBot, setIsOpenBot] = useState(false);

    useEffect(() => {
        // Set up the message event listener
        function handleChatbotClose(event: any) {
            // Check if the message is from the bot and the action is 'closeChatbot'
            if (event.data && event.data.action === "closeChatbot") {
                setIsOpenBot(false); // Close the bot by setting its state
            }
        }

        // Add the message event listener when the component mounts
        window.addEventListener("message", handleChatbotClose);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("message", handleChatbotClose);
        };
    }, []); //
    return (
        <div className='bot-wrapper h-screen'>
            <button
                onClick={() => setIsOpenBot(!isOpenBot)}
                id='toggleButton'
                style={{
                    width: "65px",
                    height: "65px",
                    position: "fixed",
                    bottom: "10px",
                    right: "10px",
                    cursor: "pointer",
                    borderRadius: "100px",
                    backgroundColor: "#0B6B20",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <ChatIcon className='w-[24px] h-[24px]' />
            </button>
            {/* {isOpenBot && ( */}
            <iframe
                id='myIframe'
                title='this is Demo'
                src={`/iframe`}
                style={{
                    border: "none", // Do not remove this line
                    display: `${isOpenBot ? "block" : "none"}`, // Do not remove this line
                    zIndex: "999",
                    width: "378px", // Set as per your need
                    height: "383px", // Set as per your need
                    borderRadius: "15px 16px 15px 16px", // Set as per your need
                    position: "fixed", // Set as per your need
                    bottom: "64px", // Set as per your need
                    right: "78px", // Set as per your need
                    boxShadow: "0px 4px 4px 0px #00000040", // Set as per your need
                }}
            />
            {/* )} */}
        </div>
    );
};

export default UmWeltDemo;
